.line-item {
  display: grid;
  grid-template-columns: auto 6rem 6rem;
  margin: 0.25rem 0;

  &.total {
    margin: 1rem 0;
  }

  .item-title {
    margin-right: 2rem;
  }
  .item-quantity {
    font-weight: 100;
    font-style: italic;
    text-align: right;
  }

  .subtotal {
    font-weight: bold;
    text-align: right;
  }

  .total-label {
    grid-column: 2;
    text-align: right;
  }
  .total-value {
    grid-column: 3;
    text-align: right;
    font-weight: bold;
  }
}
