.confirmation {
  padding: 2rem;
  background-color: #eee;
  width: 75%;
  margin: 6rem auto;

  .buttons {
    margin-top: 2rem;
    text-align: center;
  }
}
