@import "../../breakpoints";

.page-offer {
  margin: 0 auto;
  max-width: 60rem;

  @include breakpoint("medium") {
    .item-results {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }
  }

}
