@import "../../breakpoints";

.page-payment {

  @include breakpoint("medium") {
    display: grid;
    grid-template-columns: auto 20rem;
    grid-column-gap: 1rem;
  }

  .side-bar {
    grid-row: 1;
    grid-column: 2;
    background-color: #eee;
    padding: 1rem;
    ul {
      padding-left: 0;
    }
  }

  .main-content {
    grid-row: 1;
    grid-column: 1;
  }

  form{
    .MuiFormControl-root {
      margin-right: 0.25rem;
      width: 100%;
      @include breakpoint("medium") {
        width: auto;
      }
    }

    h3 {
      margin-top: 2.5rem;
      margin-bottom: 0;
    }
    .address, .cardNumber {
      width: 100%;
    }
    .city {
      min-width: 16rem;
    }
    .cardholderName {
      min-width: 16rem;
    }
    .expiry, .ccv {
      width: 8rem;
    }

    .error {
      margin-top: 1.5rem;
    }

    .buttons {
      margin-top: 2.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .progress {
        margin: 0.5rem;
        visibility: hidden;
        &.submitting {
          visibility: visible;
        }
      }
    }
  }
}
