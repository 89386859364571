.cart-item {
  display: flex;

  .item-image {
    width: 8rem;
    margin: 0.5rem 0;
  }

  .item-detail {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;
    h4 {
      margin-top: 0;
      margin-bottom: 0.25rem;
    }

  }
  .item-quantity {
    display: flex;
    justify-content: space-between;
    margin: 0;

    button svg {
      font-size: 1rem;

    }
  }
}
