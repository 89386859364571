@import "../../workspace/constants";
@import '../../workspace/breakpoints';

.item-card {
  margin-bottom: 1rem;

  @include breakpoint("medium") {
    margin-bottom: 0;
  }

  .item-image {
    padding-top: 56.25%;
  }
  .item-detail {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h2 {
      display: inline;
    }
  }
}
