.shopping-cart {
  display: grid;
  grid-template-rows: 2.5rem auto 2.5rem;
  min-width: 24rem;
  height: 100%;
  padding: 0.5rem;

  .header {
    text-align: right;
  }
  .total-row {
    display: flex;
    justify-content: space-between;
    margin: 0.75rem 1rem 0.75rem 9rem;
    .total {
      font-weight: 700;
    }
  }

  .empty-basket {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkout-button {
    width: 100%;
  }
}
