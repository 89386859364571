@import "./constants";
@import "breakpoints";

header {
  border-bottom: 1px solid $light-grey;
  box-shadow: 0px 3px 4px 0px rgba(180,180,180,0.15);

  .header-content {
    display: flex;
    @include contentWidth;
    min-height: 3rem;
    align-items: stretch;

    nav.NavBar {
      display: none;
      @include breakpoint("medium") {
        display: flex;
        flex: 1 1 0;
      }
    }
  }

  .header-section {
    flex: 1 1 0;
    display: flex;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
  .logo {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    text-decoration: none;
    .narrow {
      font-weight: 100;
    }
    .bold {
      font-weight: 700;
    }
  }
}

.workspace-content {
  @include contentWidth;
  max-width: 50rem;
  flex: 1 1 auto;
  padding: 2rem 0;
}
