$max-content-width: 80rem;
$margin: 1.5rem;

$breakpoints : (
  small: 37.5rem,  // 600px
  medium: 60rem,   // 960px
  large: 90rem,    // 1440px
  xlarge : $max-content-width + 3rem
);

@mixin breakpoint($bp) {
  @if not map_has_key($breakpoints, $bp) {
    @error "Can't find $breakpoint: #{$bp} in $breakpoints map.";
  }
  @media (min-width: map_get($breakpoints, $bp)) { @content; }
}

@mixin contentWidth {
  margin: 0 $margin;
  @include breakpoint(medium) {
    margin: 0 auto;
    max-width: 60rem;
  }
}
